@media screen and (min-width: 480px){
    .tokenEconomicsH2 {
        display: flex;
        justify-content: center;
        align-items: center;    }
}
@media screen and (max-width: 479px) {
    .tokenEconomicsH2 {

    }
}