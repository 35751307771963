@media screen and (min-width: 1024px){
.appP {
    font-size: 20px;
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 30px;
}

.sectionH2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nextDiv {
    margin-top: 10%;
    margin-left: 78%;
}

.next {
    color: #6e6d6d;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 10px;
}

.next:hover:not(.active) {
    background-color: #555;
    color: #982e2e;
}
.youtubeVideo{
    display: flex;
    margin-top: 40px;
    margin-left: 250px;
    width: 560px;
    height: 300px;
    border-radius: 20px;
    border: none;
}

}
@media screen and (min-width: 480px) and  (max-width: 1023px){
    .appP {
        font-size: 20px;
        margin-left: 60px;
        margin-right: 60px;
        margin-top: 30px;
    }

    .sectionH2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nextDiv {
        margin-top: 10%;
        margin-left: 78%;
    }

    .next {
        color: #6e6d6d;
        padding: 8px 16px;
        text-decoration: none;
        border-radius: 10px;
    }

    .next:hover:not(.active) {
        background-color: #555;
        color: #982e2e;
    }
    .youtubeVideo{
        display: flex;
        margin-top: 40px;
        width: 560px;
        height: 300px;
        border-radius: 20px;
        border: none;
    }

}
@media screen and (max-width: 479px){
    .appP {

        text-align: center;
        font-size: 10px;
        margin-right: 10px;
        margin-top: 30px;
    }
    .sectionH2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nextDiv {
        margin-top: 10%;
        margin-left: 70%;
    }

    .next {
        color: #6e6d6d;
        padding: 8px 2px;
        text-decoration: none;
        border-radius: 10px;
        font-size: 10px;
    }

    .next:hover:not(.active) {
        background-color: #555;
        color: #982e2e;
    }
    .youtubeVideo{
        margin-left: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 90px;
        border-radius: 5px;
        border: 1px solid black;
    }
}
