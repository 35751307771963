@media screen and (min-width: 480px){
.leftMenuDiv {
    margin-top: 70px
}

.leftMenuLu {
    margin: 10px;
    padding: 5px;
    width: 14%;
    background-color: black;
    position: fixed;
    height: 100%;
    overflow: auto;
    border-right: 1px solid #b0a9a9;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.LeftList {
    display: block;
    color: #6e6d6d;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 10px;
    margin-top: 5px;

}

.LeftList:hover:not(.active) {
    background-color: #555;
    color: #982e2e;
}
.nav-btn{
    margin-top: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
}

}
@media screen and (max-width: 479px){
    .leftMenuDiv {
        margin-top: 70px
    }

    .leftMenuLu {
        list-style-type: none;
        padding: 1px;
        width: 11%;
        background-color: black;
        position: fixed;
        height: 100%;
        overflow: auto;
        border-right: 1px solid #b0a9a9;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .LeftList {
        display: block;
        color: #6e6d6d;
        padding: 8px ;
        text-decoration: none;
        border-radius: 10px;
        margin-top: 5px;
        font-size: 10px;
        margin-left: -13px;
    }
    .LeftList:hover:not(.active) {
        background-color: #555;
        color: #982e2e;
    }
    .leftMenuLu:hover{
        color: white;
    }
    .nav-btn{
        position: fixed;
        cursor: pointer;
        outline: none;
        visibility: visible;
        opacity: 1 ;
        background-color: transparent;
        color: #ffffff;
        border: none;
    }
    .leftMenuLu {
    padding: 10px;
    position: fixed;
    height: 100%;
    width: 14%;
        gap: 1.5rem;
        background-color: #000000;
        transition: 1s;
        transform: translateY(-200vh );
    }
    .responsive_nav{
        transform: none;
    }
}



