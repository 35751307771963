@media screen and (min-width: 480px){
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    background-color: black;
}

.pageDiv {
    margin-left: 20%;
    padding: 2px 16px;
    height: 1000px;
    color: #C0C0C0;
}
}
@media screen and (max-width: 479px){
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        background-color: #000000;
    }
    .pageDiv {
        text-align: center;
        margin-left: 20%;
        padding: 2px 16px;
        color: #C0C0C0;
    }
}


